















































































































import Vue from 'vue';
import moment from 'moment';
import * as api from '@/api/certificationAudit';
export default Vue.extend({
  name: 'certificationAuditList',
  data() {
    return {
      tableLoading: false,
      certificationType: 'enterprise',
      searchForm: {
        certificationName: null,
        creationTime: [],
        auditStatus: '0',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      enterpriseTenantColumns: [
        {
          title: '认证企业名称',
          dataIndex: 'enterpriseName',
          scopedSlots: { customRender: 'enterpriseName' },
          width: '200px',
        },
        {
          title: '认证申请人',
          dataIndex: 'realName',
        },
        {
          title: '用户来源',
          dataIndex: 'applySource',
          scopedSlots: { customRender: 'applySource' },
        },
        {
          title: '认证申请账号',
          dataIndex: 'userCode',
        },
        {
          title: '认证申请时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '状态',
          dataIndex: 'auditStatus',
          scopedSlots: { customRender: 'auditStatus' },
        },
      ],
      personalTenantColumns: [
        {
          title: '认证个人姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'personalName' },
          width: '200px',
        },
        {
          title: '身份证号码',
          dataIndex: 'idCard',
        },
        {
          title: '用户来源',
          dataIndex: 'applySource',
          scopedSlots: { customRender: 'applySource' },
        },
        {
          title: '认证申请账号',
          dataIndex: 'userCode',
        },
        {
          title: '认证申请时间',
          dataIndex: 'recDate',
          scopedSlots: { customRender: 'recDate' },
        },
        {
          title: '状态',
          dataIndex: 'auditStatus',
          scopedSlots: { customRender: 'auditStatus' },
        },
      ],
      certificationAuditData: [],
    };
  },
  mounted() {
    this.getTenantList();
  },
  methods: {
    moment,
    // 转换认证信息
    createAuditStateText(val) {
      if (val == '0') {
        return '待审核';
      } else if (val == '1') {
        return '审核通过';
      } else {
        return '审核不通过';
      }
    },
    handleSubmit() {
      this.pagination.current = 1;
      this.getTenantList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.getTenantList();
    },
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getTenantList();
    },
    onNameClick(key: string) {
      // 跳转的企业认证审核详情页
      if (this.certificationType === 'enterprise') {
        this.$router.push({
          path: '/platform/certificationAudit/details',
          query: { id: key },
        });
      }
      // 跳转的个人认证审核详情页
      if (this.certificationType === 'personal') {
        this.$router.push({
          path: '/platform/certificationAudit/personalDetails',
          query: { id: key },
        });
      }
    },
    getTenantList() {
      if (this.certificationType === 'enterprise') {
        this.getEnterpriseTenantList();
      }
      if (this.certificationType === 'personal') {
        this.getPersonalTenantList();
      }
    },
    getEnterpriseTenantList() {
      this.tableLoading = true;
      api
        .getEnterpriseTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.certificationName,
          '',
          '',
          this.searchForm.creationTime[0],
          this.searchForm.creationTime[1],
          this.searchForm.auditStatus
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.certificationAuditData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getPersonalTenantList() {
      this.tableLoading = true;
      api
        .getPersonalTenantList(
          this.pagination.current,
          this.pagination.pageSize,
          this.searchForm.certificationName,
          this.searchForm.creationTime[0],
          this.searchForm.creationTime[1],
          this.searchForm.auditStatus
        )
        .then((res) => {
          if (res.status === 200) {
            this.pagination.total = res.result.total;
            this.certificationAuditData = res.result.list;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    certificationTypeChange() {
      this.handleSearchReset();
    },
  },
});
